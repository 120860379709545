(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:teacherReport
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="components">
       <file name="index.html">
        <teacher-report></teacher-report>
       </file>
     </example>
   *
   */
  angular
    .module('components')
    .directive('teacherReport', teacherReport);

  function teacherReport() {
    return {
      restrict: 'EA',
      scope: {
        module: '='
      },
      templateUrl: 'components/teacher-report/teacher-report-directive.tpl.html',
      replace: false,
      controllerAs: 'teacherReport',
      controller: 'TeacherReportCtrl'
    };
  }
}());
